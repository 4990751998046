import { DateTime, DurationLikeObject } from "luxon";
import { useEffect, useState } from "react";

// We sometimes use graphQLs "poll" feature to refresh queried data periodically
// This polling isn't active if the users computer is suspended.
// This hook helps to make sure that data is refetched if stale, after a users computer is suspended and resumed

export const useRefetchStaleDataOnFocus = (options: {
    data: any; // The data being loaded - we watch this to determine when it was last fetched
    refetchData: VoidFunction; // Function to trigger to refetch data
    timeWindow: DurationLikeObject; // The duration since the last fetch after which the data is considered stale
}) => {
    const { data, refetchData, timeWindow } = options;

    const [dataLastFetchTime, setDataLastFetchTime] = useState(DateTime.now());

    useEffect(() => {
        // Update dataLastFetchTime each time new data is received
        if (data) {
            setDataLastFetchTime(DateTime.now());
        }
    }, [data]);

    useEffect(() => {
        // Refetch data for teachers when the window is re-focused if it has been >30 mins since our last fetch
        // This fills gaps in polling when the computer is suspended
        const refetchDataIfStale = () => {
            if (dataLastFetchTime.plus(timeWindow) < DateTime.now()) {
                refetchData();
                setDataLastFetchTime(DateTime.now());
            }
        };
        window.addEventListener("focus", refetchDataIfStale);
        return () => window.removeEventListener("focus", refetchDataIfStale);
    }, [dataLastFetchTime, refetchData]);
};
