import _ from "lodash";
import {
    DateTime,
    DateTimeMaybeValid,
    Duration,
    DurationMaybeValid,
    Interval,
    IntervalMaybeValid,
    WeekdayNumbers,
} from "luxon";
import { COMMUNITY_EVENT_TYPES_FOR_MEMBERS } from "./constants";
import { SanghaSessionCycle, Session } from "./types";

export function validateLuxon(luxonObject: DateTimeMaybeValid): DateTime<true>;
export function validateLuxon(luxonObject: IntervalMaybeValid): Interval<true>;
export function validateLuxon(luxonObject: DurationMaybeValid): Duration<true>;
export function validateLuxon(
    luxonObject: DateTimeMaybeValid | IntervalMaybeValid | DurationMaybeValid,
) {
    if (!luxonObject.isValid) {
        throw new Error("Invalid Luxon object");
    } else {
        return luxonObject;
    }
}

export const objWithId = (obj: any, id: string) => (obj ? { id: id, ...obj } : obj);

export const idObjMapToArray = (objMap: any) =>
    Object.keys(objMap).map((key: string, i: number) => objWithId(objMap[key], key));

export const refToObj = (ref: any) => {
    if (ref.exists) {
        return {
            id: ref.id,
            ...ref.data(),
        };
    } else {
        return null;
    }
};

export function objArrayToObjMap<T>(arr: Array<T>, key: string = "id"): { [key: string]: T } {
    return arr.reduce(function (obj: { [key: string]: any }, item: any) {
        obj[item[key]] = item;
        return obj;
    }, {});
}

export function selectAllOrNoneById<T>(selector: (id: string) => T | null, ids: string[]): T[] {
    const matches = ids.map((id) => selector(id)).filter((obj) => obj !== null) as T[];
    return matches.length === ids.length ? matches : [];
}

export function allOrNone<T>(arr: Array<T | undefined | null> | undefined): T[] | null {
    if (!arr) return null;

    const filtered = arr.filter((obj) => obj !== null);
    return filtered.length === arr.length ? (filtered as T[]) : null;
}

export function objMapFromKeyValueArrays<T>(keys: string[], values: T[]): { [key: string]: T } {
    if (keys.length !== values.length) {
        throw Error("Keys and values array lengths do not match");
    }

    const objMap = {} as { [key: string]: T };
    keys.forEach((key, i) => {
        objMap[key] = values[i];
    });

    return objMap;
}

export function arrayWithoutNulls<T>(arr: (T | null)[]): T[] {
    return arr.filter((elem) => elem !== null) as T[];
}

export const formatTimestamp = (time: number, dateOptions?: any) => {
    const useDateOptions = dateOptions || {
        weekday: "short",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
    };

    if (isNaN(time) || time <= 0) return "Invalid time";

    return new Intl.DateTimeFormat("en-US", useDateOptions).format(time);
};

export const humanReadableTimeZone = (timeZone?: string) => {
    if (!timeZone) return null;
    return DateTime.now().setZone(timeZone).offsetNameLong;
};

export const isValidTimeZone = (timeZone: string) => {
    try {
        formatTimestamp(new Date().getTime(), {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: timeZone,
        });
        return true;
    } catch {
        return false;
    }
};

export const dateTimeFromString = (dateString: string, timezone: string) =>
    validateLuxon(
        DateTime.fromISO(dateString, {
            zone: timezone,
        }),
    );

export const getFormattedDate = (
    date: DateTime,
    timezone?: string,
    format: Intl.DateTimeFormatOptions = DateTime.TIME_SIMPLE,
) => {
    return date
        .setZone(timezone || date.zoneName || undefined)
        .toLocaleString(format)
        .toLowerCase();
};

export const getFormattedDateFromString = (
    date: string,
    timezone: string,
    format: Intl.DateTimeFormatOptions = DateTime.TIME_SIMPLE,
) => {
    return dateTimeFromString(date, timezone).toLocaleString(format).toLowerCase();
};

export const secondsSinceEpochForDateTime = (datetime: DateTime) =>
    Math.floor(datetime.toMillis() / 1000);

export const dateTimeForSecondsSinceEpoch = (timestamp: number) =>
    DateTime.fromMillis(timestamp * 1000);

export const localISOStringForTimestamp = (time: number) => {
    const dt = new Date(time);
    return new Date(dt.getTime() - dt.getTimezoneOffset() * 60000).toISOString().slice(0, -8);
};

export const localTimeStringForTimestamp = (time: number) => {
    const dt = new Date(time);
    return new Date(dt.getTime() - dt.getTimezoneOffset() * 60000).toISOString().slice(11, -8);
};

export const currentTimeRoundedToNearestMinutes = (roundedToMinutes: number) => {
    const roundFactor = roundedToMinutes * (60 * 1000);
    return new Date(Math.round(new Date().getTime() / roundFactor) * roundFactor);
};

// which nth weekday in a month does this date represent
// e.g. if the date is a Thursday in May, which Thursday is it (1st, 2nd, 3rd)
type MonthlyWeekdayWeekIndex = 1 | 2 | 3 | 4 | 5;
export const nthMonthlyWeekdayForDate = (date: DateTime) =>
    (Math.floor((date.get("day") - 1) / 7) + 1) as MonthlyWeekdayWeekIndex;

const monthlySessionDateInMonth = (
    sessionAnchorDateTime: DateTime,
    monthDateTime: DateTime,
    timeZone: string,
) => {
    const firstDayOfMonth = monthDateTime.set({
        day: 1,
        hour: sessionAnchorDateTime.get("hour"),
        minute: sessionAnchorDateTime.get("minute"),
        second: 0,
        millisecond: 0,
    });

    // the start dates of the two months may have some delta. never more than 7 days
    const firstWeekDaysShift =
        (sessionAnchorDateTime.get("weekday") - firstDayOfMonth.get("weekday") + 7) % 7;

    // nthWeekday returns 1-indexed, subtract by 1 for weeks to add
    const weeksShift = nthMonthlyWeekdayForDate(sessionAnchorDateTime) - 1;

    const result = firstDayOfMonth
        .plus({ weeks: weeksShift })
        .plus({ days: firstWeekDaysShift })
        .setZone(timeZone);
    return result;
};

export const nextWeeklyDateFromWeekdayAndTime = (
    weekday: WeekdayNumbers,
    hour: number,
    minute: number,
    startDate: DateTime,
    timeZone: string,
) => {
    const startDateLocal = startDate.setZone(timeZone);

    const sessionDateThisWeek = startDateLocal.set({
        weekday,
        hour,
        minute,
        second: 0,
        millisecond: 0,
    });
    // Ensure that the calculated date is after the startDate
    return sessionDateThisWeek < startDateLocal
        ? sessionDateThisWeek.plus({ days: 7 })
        : sessionDateThisWeek;
};

export const nextWeeklyDateFromAnchor = (
    anchorDateTime: DateTime,
    startDate: DateTime,
    timeZone: string,
) => {
    const anchorDateTimeLocal = anchorDateTime.setZone(timeZone);
    return nextWeeklyDateFromWeekdayAndTime(
        anchorDateTimeLocal.get("weekday") as WeekdayNumbers,
        anchorDateTimeLocal.get("hour"),
        anchorDateTimeLocal.get("minute"),
        startDate,
        timeZone,
    );
};

export const nextTwiceMonthlyDateFromAnchor = (
    anchorDateTime: DateTime,
    startDate: DateTime,
    timeZone: string,
) => {
    const anchorDateTimeLocal = anchorDateTime.setZone(timeZone);
    const anchorDateWeekNumber = nthMonthlyWeekdayForDate(anchorDateTimeLocal);

    if (anchorDateWeekNumber > 4) {
        throw new Error("Cannot anchor twice monthly group around a 5th week session");
    }

    // Anchor date for the first twice monthly session each month
    const firstSessionAnchor =
        anchorDateWeekNumber > 2 ? anchorDateTimeLocal.minus({ weeks: 2 }) : anchorDateTimeLocal;
    const firstSessionInMonth = monthlySessionDateInMonth(firstSessionAnchor, startDate, timeZone);

    if (firstSessionInMonth > startDate && firstSessionInMonth > anchorDateTime) {
        // First session in month is after the startDate
        return firstSessionInMonth;
    } else if (firstSessionInMonth.plus({ weeks: 2 }) > startDate) {
        // Second session in month is after the startDate
        return firstSessionInMonth.plus({ weeks: 2 });
    } else {
        // First session of next month
        return monthlySessionDateInMonth(
            firstSessionAnchor,
            startDate.plus({ month: 1 }),
            timeZone,
        );
    }
};

const nextMonthlyDateFromAnchor = (
    anchorDateTime: DateTime,
    afterDateTime: DateTime,
    timeZone: string,
) => {
    const anchorDateTimeLocal = anchorDateTime.setZone(timeZone);
    const dateTimeLocal = afterDateTime.setZone(timeZone);
    const sessionDateThisMonth = monthlySessionDateInMonth(
        anchorDateTimeLocal,
        dateTimeLocal,
        timeZone,
    );
    return sessionDateThisMonth < dateTimeLocal
        ? monthlySessionDateInMonth(
              anchorDateTimeLocal,
              dateTimeLocal.plus({ months: 1 }),
              timeZone,
          )
        : sessionDateThisMonth;
};

export const firstSessionForScheduleAfterDate = (
    anchorDateTime: DateTime,
    afterDateTime: DateTime,
    sanghaTimeZone: string,
    cycle: SanghaSessionCycle,
) => {
    const timeZone = sanghaTimeZone || "America/Los_Angeles";

    const anchorDateTimeLocal = anchorDateTime.setZone(timeZone);
    const dateTimeLocal = afterDateTime.setZone(timeZone);
    if (anchorDateTimeLocal > dateTimeLocal) return anchorDateTimeLocal;
    switch (cycle) {
        case SanghaSessionCycle.weekly:
            return nextWeeklyDateFromAnchor(anchorDateTime, afterDateTime, timeZone);
        case SanghaSessionCycle.monthly:
            return nextMonthlyDateFromAnchor(anchorDateTime, afterDateTime, timeZone);
        case SanghaSessionCycle.twiceMonthly:
            return nextTwiceMonthlyDateFromAnchor(anchorDateTimeLocal, dateTimeLocal, timeZone);
        default:
            throw new Error(`invalid cycle ${cycle}`);
    }
};

export const timeDifferencesBetweenZonesDuringYear = (
    origTimeZone: string,
    destTimeZone: string,
    anchorTime: DateTime,
) => {
    let origTime = anchorTime.setZone(origTimeZone);

    const nextYearOrig = origTime.plus({ years: 1 });
    const timeDifferences = [] as number[];

    while (origTime < nextYearOrig) {
        const destTime = origTime.setZone(destTimeZone);
        const destTimeConverted = origTime.set({
            year: destTime.year,
            month: destTime.month,
            day: destTime.day,
            hour: destTime.hour,
            minute: destTime.minute,
        });

        const minutesDifference = destTimeConverted.diff(destTime).shiftTo("minutes").minutes;
        timeDifferences.push(minutesDifference);

        origTime = origTime.plus({ days: 1 });
    }

    return _.uniq(timeDifferences).sort();
};

export const timezonesHaveDifferentDSTSchedules = (timeZone1: string, timeZone2: string) => {
    const offsets = timeDifferencesBetweenZonesDuringYear(
        timeZone1,
        timeZone2,
        DateTime.now().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    );
    return offsets.length > 1;
};

export const sessionDatesForCycleInNextYear = (
    firstSession: DateTime,
    cycle: SanghaSessionCycle,
    originalTimeZone: string,
    localTimeZone: string,
) => {
    let sessionDate = firstSession.setZone(originalTimeZone);
    const nextYearDate = sessionDate.plus({ years: 1 });
    const localSessionDates = [] as DateTime[];
    while (sessionDate < nextYearDate) {
        localSessionDates.push(sessionDate.setZone(localTimeZone));
        if (cycle === "weekly") {
            sessionDate = sessionDate.plus({ weeks: 1 });
        } else if (cycle === "monthly") {
            sessionDate = monthlySessionDateInMonth(
                sessionDate,
                sessionDate.set({ month: sessionDate.month + 1 }),
                originalTimeZone,
            );
        }
    }
    return localSessionDates;
};

export const prettyTimeUntilDate = (time: number) => {
    const distance = time - new Date().getTime();

    return {
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
    };
};

export class Timer {
    running = false;
    callback = () => null;
    interval = 1000;

    constructor(callback: () => any, interval = 1000) {
        this.callback = callback;
        this.interval = interval;
    }

    schedulePing() {
        this.callback();
        setTimeout(() => {
            if (this.running) this.schedulePing();
        }, this.interval);
    }

    run() {
        this.running = true;
        this.schedulePing();

        return () => {
            this.running = false;
        };
    }
}

export const shortenedText = (text: string, limit: number) => {
    if (!text) return null;

    if (text.length > limit) {
        const shortened = text.substring(0, limit);
        return shortened.substring(0, shortened.lastIndexOf(" ")) + "...";
    }

    return text;
};

export const isValidEmail = (text: string) => {
    const re =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(text).toLowerCase());
};

export const splitOnce = (s: string, on: string) => {
    const [first, ...rest] = s.split(on);
    return [first, rest.length > 0 ? rest.join(on) : null];
};

export const daysBetweenDates = (
    a: DateTime | null | undefined,
    b: DateTime | null | undefined,
) => {
    if (!a || !b) {
        return undefined;
    }
    return Math.round(a.diff(b, "days").days);
};

export const IANATimezones = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Fort_Nelson",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port_of_Spain",
    "America/Port-au-Prince",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",
    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Atyrau",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Colombo",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Famagusta",
    "Asia/Gaza",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Istanbul",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kathmandu",
    "Asia/Khandyga",
    "Asia/Kolkata",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qostanay",
    "Asia/Qyzylorda",
    "Asia/Riyadh",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ulaanbaatar",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yangon",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faroe",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/St_Helena",
    "Atlantic/Stanley",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Currie",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",
    "Etc/GMT",
    "Etc/GMT-0",
    "Etc/GMT-1",
    "Etc/GMT-10",
    "Etc/GMT-11",
    "Etc/GMT-12",
    "Etc/GMT-13",
    "Etc/GMT-14",
    "Etc/GMT-2",
    "Etc/GMT-3",
    "Etc/GMT-4",
    "Etc/GMT-5",
    "Etc/GMT-6",
    "Etc/GMT-7",
    "Etc/GMT-8",
    "Etc/GMT-9",
    "Etc/GMT+0",
    "Etc/GMT+1",
    "Etc/GMT+10",
    "Etc/GMT+11",
    "Etc/GMT+12",
    "Etc/GMT+2",
    "Etc/GMT+3",
    "Etc/GMT+4",
    "Etc/GMT+5",
    "Etc/GMT+6",
    "Etc/GMT+7",
    "Etc/GMT+8",
    "Etc/GMT+9",
    "Etc/GMT0",
    "Etc/UTC",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Nicosia",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "Factory",
    "GMT",
    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",
    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Enderbury",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Wake",
    "Pacific/Wallis",
    "UTC",
] as const;

export const isSessionACommunityEvent = (session: Session) => {
    return COMMUNITY_EVENT_TYPES_FOR_MEMBERS.includes(session.type);
};
